@tailwind base;
@tailwind components;
@tailwind utilities;

html, body {
  @apply antialiased overflow-x-hidden;
  -webkit-tap-highlight-color: transparent;
}

body {
  @apply bg-gray-100 min-h-screen p-2 sm:p-4;
}

.chat-container {
  @apply w-full mx-auto bg-white shadow-xl rounded-lg overflow-hidden;
  max-width: min(768px, 100%);
}

.message-bubble {
  @apply relative px-3 sm:px-4 py-2 rounded-lg max-w-[90%] sm:max-w-[80%] text-sm sm:text-base;
}

.typing-indicator {
    @apply flex space-x-1;
}

@keyframes fadeIn {
    from { opacity: 0; transform: translateY(10px); }
    to { opacity: 1; transform: translateY(0); }
}

.animate-fade-in {
    animation: fadeIn 0.3s ease-out forwards;
}